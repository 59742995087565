import * as dwebg from 'dwebg';

type DwebgClient = dwebg.DwebgClient;

interface Dwebg {
    client: DwebgClient | null;
    login: (refreshToken: string, accessToken?: string) => Promise<DwebgClient>;
    logout: () => Promise<void>;
    waitForClient: () => Promise<DwebgClient>;
}

export default defineNuxtPlugin((nuxtApp) => {

    const config = useRuntimeConfig();
    // ensure env vars are set:
    if (!config.public.VITE_DWEBG_URL) {
        console.error('DWEBG_URL env var not set');
        return;
    }

    const accessToken_ = useCookie('accessToken');
    const refreshToken = useCookie('refreshToken');
    const lastRefresh = useCookie('lastRefresh');
    const cartId = useCookie('cartId');

    const refreshAccessToken = async () => {
        if (refreshToken.value) {
            const config = useRuntimeConfig();
            const response = await fetch(
                `${config.public.VITE_KEYCLOAK_BASE_URL}/realms/sup/protocol/openid-connect/token`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({
                        'grant_type': 'refresh_token',
                        'client_id': 'end-user',
                        'refresh_token': refreshToken.value,
                    }),
                });

            const {access_token} = await response.json();
            // console.log('>>>>> Got new access token: ', access_token);
            if (access_token) {
                accessToken_.value = access_token;
                lastRefresh.value = new Date().toISOString();
            } else {
                // console.log('>>>>> No access token. Resetting all');
                refreshToken.value = null;
                accessToken_.value = null;
                lastRefresh.value = null;
            }
        }
    };

    const client: Dwebg = {
        client: null as DwebgClient | null,
        logout: async () => {
            // const config = useRuntimeConfig();
            // await fetch(
            //     `${config.public.VITE_KEYCLOAK_BASE_URL}/realms/sup/protocol/openid-connect/logout`,
            //     {
            //         method: 'POST',
            //         headers: {
            //             'Content-Type': 'application/x-www-form-urlencoded',
            //         },
            //         body: new URLSearchParams({
            //             'client_id': 'end-user',
            //         }),
            //     });
            refreshToken.value = null;
            accessToken_.value = null;
            lastRefresh.value = null;
            // cartId.value = null;

            client.client?.close();
            client.client = null;
        },
        waitForClient: async () => {
            if (client.client) {
                // console.log('>>>>> Already have client');
                await client.client.waitUntilAuthenticated();
                return client.client;
            }
            // console.log('>>>>> Last refresh: ', lastRefresh.value);
            const dwebgClient = new dwebg.DwebgClient({
                url: config.public.VITE_DWEBG_URL as string,
                getJwt: async (forceRefresh: boolean) => {
                    // const lastRefreshStamp = lastRefresh.value ? new Date(lastRefresh.value).getTime() : 0;
                    // const _2mins = 1000 * 60 * 2;
                    if (forceRefresh || !accessToken_.value) {
                        // console.log('>>>>> Refreshing JWT');
                        await refreshAccessToken();
                        // console.log('>>>>> Done. New JWT: ', accessToken_.value);
                    } else {
                        // console.log('>>>>> Using cached JWT:', accessToken_.value);
                    }
                    if (!accessToken_.value) {
                        // log out:
                        throw new Error('No access token');
                    }
                    // console.log('>>>>> Returning JWT: ', accessToken_.value);
                    return accessToken_.value;
                },
                autoReconnect: true,
            });
            client.client = dwebgClient;

            if (accessToken_.value || refreshToken.value) {
                // console.log('>>>>> Connecting and waiting for auth');
                await dwebgClient.connectAndWaitUntilAuthenticated();

                // Get user view:
                // const userView = await dwebgClient.query('select * from ui.my_user');
                // console.log('>>>>> User view: ', userView.labelledRows());

                // console.log('>>>>> USER: ', (await dwebgClient.queryOne('select get_user_id() as userid')));
            }
            return dwebgClient;
        },
        login: async (refreshToken_: string, accessToken?: string) => {
            // console.log('>>>>> Logging in: ', refreshToken_);
            if (client.client) {
                //TODO
                client.client.close();
                client.client = null;
            }
            refreshToken.value = refreshToken_;
            if (accessToken) {
                accessToken_.value = accessToken;
                lastRefresh.value = new Date().toISOString();
            }
            return await client.waitForClient();
        }
    };

    return {
        provide: {
            dwebg: client,
        },
    };
});
