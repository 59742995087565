import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as BookWatermarkhsZyH8Gh3kMeta } from "/app/pages/books/BookWatermark.vue?macro=true";
import { default as indextEIsPare8rMeta } from "/app/pages/books/index.vue?macro=true";
import { default as paperbackNpowZWZ0KzMeta } from "/app/pages/books/paperback.vue?macro=true";
import { default as PaperbackInfoV2WMbMECajMeta } from "/app/pages/books/PaperbackInfo.vue?macro=true";
import { default as specialty_45hardcoverEQaceFpjMHMeta } from "/app/pages/books/specialty-hardcover.vue?macro=true";
import { default as SpecialtyHardcoverDescriptionmBChtaFOKHMeta } from "/app/pages/books/SpecialtyHardcoverDescription.vue?macro=true";
import { default as SpecialtyHardcoverInfopav6YEysviMeta } from "/app/pages/books/SpecialtyHardcoverInfo.vue?macro=true";
import { default as standard_45hardcoverTO0KAIagCcMeta } from "/app/pages/books/standard-hardcover.vue?macro=true";
import { default as StandardHardcoverInfoIlMSZy9xexMeta } from "/app/pages/books/StandardHardcoverInfo.vue?macro=true";
import { default as cart9SktLKPpbzMeta } from "/app/pages/cart.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as extrasa5ZXekTqrGMeta } from "/app/pages/extras.vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as materialsFAhgrWqTD8Meta } from "/app/pages/materials.vue?macro=true";
import { default as postersAyAT219Mk5Meta } from "/app/pages/posters.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as shipping_45and_45returnsZwuVPmxHDHMeta } from "/app/pages/shipping-and-returns.vue?macro=true";
import { default as terms_45of_45useA3Owpnmw23Meta } from "/app/pages/terms-of-use.vue?macro=true";
export default [
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about",
    path: aboutu8Sb32ihwPMeta?.path ?? "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: BookWatermarkhsZyH8Gh3kMeta?.name ?? "books-BookWatermark",
    path: BookWatermarkhsZyH8Gh3kMeta?.path ?? "/books/BookWatermark",
    meta: BookWatermarkhsZyH8Gh3kMeta || {},
    alias: BookWatermarkhsZyH8Gh3kMeta?.alias || [],
    redirect: BookWatermarkhsZyH8Gh3kMeta?.redirect || undefined,
    component: () => import("/app/pages/books/BookWatermark.vue").then(m => m.default || m)
  },
  {
    name: indextEIsPare8rMeta?.name ?? "books",
    path: indextEIsPare8rMeta?.path ?? "/books",
    meta: indextEIsPare8rMeta || {},
    alias: indextEIsPare8rMeta?.alias || [],
    redirect: indextEIsPare8rMeta?.redirect || undefined,
    component: () => import("/app/pages/books/index.vue").then(m => m.default || m)
  },
  {
    name: paperbackNpowZWZ0KzMeta?.name ?? "books-paperback",
    path: paperbackNpowZWZ0KzMeta?.path ?? "/books/paperback",
    meta: paperbackNpowZWZ0KzMeta || {},
    alias: paperbackNpowZWZ0KzMeta?.alias || [],
    redirect: paperbackNpowZWZ0KzMeta?.redirect || undefined,
    component: () => import("/app/pages/books/paperback.vue").then(m => m.default || m)
  },
  {
    name: PaperbackInfoV2WMbMECajMeta?.name ?? "books-PaperbackInfo",
    path: PaperbackInfoV2WMbMECajMeta?.path ?? "/books/PaperbackInfo",
    meta: PaperbackInfoV2WMbMECajMeta || {},
    alias: PaperbackInfoV2WMbMECajMeta?.alias || [],
    redirect: PaperbackInfoV2WMbMECajMeta?.redirect || undefined,
    component: () => import("/app/pages/books/PaperbackInfo.vue").then(m => m.default || m)
  },
  {
    name: specialty_45hardcoverEQaceFpjMHMeta?.name ?? "books-specialty-hardcover",
    path: specialty_45hardcoverEQaceFpjMHMeta?.path ?? "/books/specialty-hardcover",
    meta: specialty_45hardcoverEQaceFpjMHMeta || {},
    alias: specialty_45hardcoverEQaceFpjMHMeta?.alias || [],
    redirect: specialty_45hardcoverEQaceFpjMHMeta?.redirect || undefined,
    component: () => import("/app/pages/books/specialty-hardcover.vue").then(m => m.default || m)
  },
  {
    name: SpecialtyHardcoverDescriptionmBChtaFOKHMeta?.name ?? "books-SpecialtyHardcoverDescription",
    path: SpecialtyHardcoverDescriptionmBChtaFOKHMeta?.path ?? "/books/SpecialtyHardcoverDescription",
    meta: SpecialtyHardcoverDescriptionmBChtaFOKHMeta || {},
    alias: SpecialtyHardcoverDescriptionmBChtaFOKHMeta?.alias || [],
    redirect: SpecialtyHardcoverDescriptionmBChtaFOKHMeta?.redirect || undefined,
    component: () => import("/app/pages/books/SpecialtyHardcoverDescription.vue").then(m => m.default || m)
  },
  {
    name: SpecialtyHardcoverInfopav6YEysviMeta?.name ?? "books-SpecialtyHardcoverInfo",
    path: SpecialtyHardcoverInfopav6YEysviMeta?.path ?? "/books/SpecialtyHardcoverInfo",
    meta: SpecialtyHardcoverInfopav6YEysviMeta || {},
    alias: SpecialtyHardcoverInfopav6YEysviMeta?.alias || [],
    redirect: SpecialtyHardcoverInfopav6YEysviMeta?.redirect || undefined,
    component: () => import("/app/pages/books/SpecialtyHardcoverInfo.vue").then(m => m.default || m)
  },
  {
    name: standard_45hardcoverTO0KAIagCcMeta?.name ?? "books-standard-hardcover",
    path: standard_45hardcoverTO0KAIagCcMeta?.path ?? "/books/standard-hardcover",
    meta: standard_45hardcoverTO0KAIagCcMeta || {},
    alias: standard_45hardcoverTO0KAIagCcMeta?.alias || [],
    redirect: standard_45hardcoverTO0KAIagCcMeta?.redirect || undefined,
    component: () => import("/app/pages/books/standard-hardcover.vue").then(m => m.default || m)
  },
  {
    name: StandardHardcoverInfoIlMSZy9xexMeta?.name ?? "books-StandardHardcoverInfo",
    path: StandardHardcoverInfoIlMSZy9xexMeta?.path ?? "/books/StandardHardcoverInfo",
    meta: StandardHardcoverInfoIlMSZy9xexMeta || {},
    alias: StandardHardcoverInfoIlMSZy9xexMeta?.alias || [],
    redirect: StandardHardcoverInfoIlMSZy9xexMeta?.redirect || undefined,
    component: () => import("/app/pages/books/StandardHardcoverInfo.vue").then(m => m.default || m)
  },
  {
    name: cart9SktLKPpbzMeta?.name ?? "cart",
    path: cart9SktLKPpbzMeta?.path ?? "/cart",
    meta: cart9SktLKPpbzMeta || {},
    alias: cart9SktLKPpbzMeta?.alias || [],
    redirect: cart9SktLKPpbzMeta?.redirect || undefined,
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact",
    path: contactV6oPJjs9RyMeta?.path ?? "/contact",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect || undefined,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: extrasa5ZXekTqrGMeta?.name ?? "extras",
    path: extrasa5ZXekTqrGMeta?.path ?? "/extras",
    meta: extrasa5ZXekTqrGMeta || {},
    alias: extrasa5ZXekTqrGMeta?.alias || [],
    redirect: extrasa5ZXekTqrGMeta?.redirect || undefined,
    component: () => import("/app/pages/extras.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1UbT4qDpFeMeta?.name ?? "forgot-password",
    path: forgot_45password1UbT4qDpFeMeta?.path ?? "/forgot-password",
    meta: forgot_45password1UbT4qDpFeMeta || {},
    alias: forgot_45password1UbT4qDpFeMeta?.alias || [],
    redirect: forgot_45password1UbT4qDpFeMeta?.redirect || undefined,
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutHaVXWqg8UAMeta?.name ?? "logout",
    path: logoutHaVXWqg8UAMeta?.path ?? "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    alias: logoutHaVXWqg8UAMeta?.alias || [],
    redirect: logoutHaVXWqg8UAMeta?.redirect || undefined,
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: materialsFAhgrWqTD8Meta?.name ?? "materials",
    path: materialsFAhgrWqTD8Meta?.path ?? "/materials",
    meta: materialsFAhgrWqTD8Meta || {},
    alias: materialsFAhgrWqTD8Meta?.alias || [],
    redirect: materialsFAhgrWqTD8Meta?.redirect || undefined,
    component: () => import("/app/pages/materials.vue").then(m => m.default || m)
  },
  {
    name: postersAyAT219Mk5Meta?.name ?? "posters",
    path: postersAyAT219Mk5Meta?.path ?? "/posters",
    meta: postersAyAT219Mk5Meta || {},
    alias: postersAyAT219Mk5Meta?.alias || [],
    redirect: postersAyAT219Mk5Meta?.redirect || undefined,
    component: () => import("/app/pages/posters.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect || undefined,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register",
    path: registermu8R7rSP5uMeta?.path ?? "/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect || undefined,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: shipping_45and_45returnsZwuVPmxHDHMeta?.name ?? "shipping-and-returns",
    path: shipping_45and_45returnsZwuVPmxHDHMeta?.path ?? "/shipping-and-returns",
    meta: shipping_45and_45returnsZwuVPmxHDHMeta || {},
    alias: shipping_45and_45returnsZwuVPmxHDHMeta?.alias || [],
    redirect: shipping_45and_45returnsZwuVPmxHDHMeta?.redirect || undefined,
    component: () => import("/app/pages/shipping-and-returns.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect || undefined,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  }
]